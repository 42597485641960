import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Home from "./Home.js"
import Reader from "./Reader.js"

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/> }/>
        <Route path="b" element={<Outlet/> }>
          <Route index element={<div>index</div> }/>
          <Route path="ryan" element={<Reader docUrl="/Cardillac-Generic.epub"/>  }/>
          <Route path="*" element={<div>not found</div> }/>
        </Route>
        <Route path="*" element={<div>not found</div> }/>
      </Routes>

    </BrowserRouter>
  );
}
