export default function Home() {
  return (
    <CenteredText>
      <div style={{fontSize: "42px", fontFamily: "monospace"}}>
        41READER.COM
      </div>
    </CenteredText>
  );
}


const CenteredText = ({ children }) => {
  const centeredStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  };

  return (
    <div style={centeredStyle}>
      <div style={{ textAlign: 'center' }}>
        {children}
      </div>
    </div>
  );
};