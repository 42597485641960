import React, { useState, useRef } from 'react'
import { ReactReader } from 'react-reader'

export default function Reader({docUrl}){
  const [location, setLocation] = useState(0)
  const rendition = useRef(undefined)

  return (
    <div style={{ height: '100vh' }}>
      <ReactReader
        url={docUrl}
        location={location}
        locationChanged={(epubcfi: string) => setLocation(epubcfi)}
        getRendition={(_rendition: Rendition) => {
          rendition.current = _rendition
          _rendition.hooks.content.register((contents: Contents) => {
            const body = contents.window.document.querySelector('body')
            if (body) {
              body.oncontextmenu = () => {
                return false
              }
            }
          })
          rendition.current.themes.fontSize('200%')
        }}
      />
    </div>
  )
}

